.main-minha_conta {
  margin: 50px 0;
}
.listaPedidos {
  padding-left: 0;
}

/* ### Sidenav ### */
.lista-sidebar_links li a {
  font-weight: 500;
  text-decoration: none;
}
.lista-sidebar_links li a:hover {
  color: #0a98f8;
  text-decoration: none;
}
.lista-sidebar_links li a.active {
  color: #0a98f8;
}

/* ### Meus Pedidos ### */
.pedido_id {
  display: flex;
}
.pedido_id i {
  margin-right: 15px;
}
.img-bandeira-pgto {
  width: 100px;
}
.pedido_dados {
  display: flex;
  align-items: center;
  color: #666;
  font-weight: 500;
}

/* ### Meus Pedidos-detalhe ### */
.lista-pedidos {
  margin-bottom: 0;
}
.titulo-status {
  font-weight: 500;
}