@import url("https://fonts.googleapis.com/css?family=Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800");
.text-third {
    color: #ef837b;
}
.bg-light {
    background-color: #f4f7ff !important;
}
.title-desc {
    font-size: 16px;
    line-height: 1;
    margin-top: 0.3rem;
}
.intro-slider-container,
.intro-slide {
    height: 310px;
    background-color: #f5f6f9;
}
.intro-slide {
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
}
.intro-slide .intro-content {
    position: static;
    left: auto;
    top: auto;
    transform: translateY(0);
    -ms-transform: translateY(0);
}
.intro-subtitle {
    margin-bottom: 5px;
}
.intro-title {
    color: #333;
    letter-spacing: -0.03em;
    font-size: 34px;
    margin-bottom: 0;
}
.intro-price {
    color: #777;
    font-weight: 700;
    line-height: 1.2;
    font-size: 34px;
    letter-spacing: -0.025em;
    margin-top: 10px;
    margin-bottom: 1.20px;
}
.intro-price sup {
    top: -0.8em;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: -0.03em;
}
.intro-old-price {
    position: relative;
}
.intro-old-price:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #999;
}
.owl-theme .owl-nav [class*="owl-"] {
    width: 32px;
    height: 32px;
    font-size: 20px;
    margin-top: -16px;
}
.owl-theme.owl-nav-inside .owl-nav .owl-prev {
    left: 50%;
    margin-left: -584px;
}
.owl-theme.owl-nav-inside .owl-nav .owl-next {
    right: 50%;
    margin-right: -584px;
}
.owl-theme.owl-light .owl-nav [class*="owl-"] {
    border-color: rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0.7);
    color: #ccc;
}
.owl-theme.owl-nav-inside .owl-dots {
    bottom: 20px;
}
.owl-full .owl-dots {
    bottom: -20px;
}
.cat-blocks-container {
    padding-top: 5px;
}
.cat-blocks-container [class*="col-"] {
    display: flex;
    align-items: stretch;
    justify-content: center;
}
.cat-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}
.cat-block figure {
    display: inline-flex;
    align-items: center;
    position: relative;
    flex: 1;
    margin: 0;
}
.cat-block figure span {
    position: relative;
}
.cat-block figure span:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 45%;
    width: 105%;
    margin-left: -45%;
    height: 0.3rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.35s ease;
    filter: blur(3px);
    opacity: 0.5;
}
.cat-block img {
    margin-left: auto;
    margin-right: auto;
    transition: transform 0.35s ease;
}
.cat-block:hover img {
    transform: translateY(-14px);
}
.cat-block:hover figure span:after {
    opacity: 1;
}
.cat-block-title {
    color: #333;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: -0.01em;
    margin-top: 20px;
    margin-bottom: 0;
}
.banner-content {
    left: 30px;
    padding-top: 0;
}
.banner-subtitle {
    font-size: 14px;
    letter-spacing: -0.01em;
    margin-bottom: 13px;
}
.banner-title {
    color: #999;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.01em;
    margin-bottom: 12px;
}
.main .banner-title strong {
    font-weight: 600;
    color: #333;
}
.banner-link {
    display: inline-block;
    font-weight: 400;
    line-height: 1.3;
    padding: 3px 10px;
    border-radius: 20px;
    letter-spacing: -1px;
    margin-left: -1px;
    text-align: center;
    min-width: 110px;
}
.banner-link i {
    margin-left: 0.7rem;
}
.banner-link:hover,
.banner-link:focus {
    color: #fff;
    background-color: #0a98f8;
    text-decoration: none !important;
}
.nav.nav-pills .nav-link {
    font-size: 14px;
}
.nav.nav-pills .nav-item + .nav-item {
    margin-left: 5px;
}
.heading .title {
    font-weight: 600;
    letter-spacing: 3px;
}
.product.product-2 {
    background-color: #fff;
    border: 1px solid #ebebeb;
    margin-bottom: 20px;
}
.product.product-2 .product-nav-dots {
    margin-bottom: 15px;
}
.product-cat {
    color: #ccc;
}
.product-body {
    overflow: hidden;
}
.btn-product + .btn-product {
    border-left-style: solid;
}
.product-action:not(.product-action-dark) {
    background-color: #f6f7fa;
}
.product-action:not(.product-action-dark) .btn-product + .btn-product {
    border-left-color: #d1d1d3;
}
.product-action:not(.product-action-dark) .btn-product:not(:hover):not(:focus) {
    color: #333;
}
.product-action-dark .btn-product:not(:hover):not(:focus) {
    color: #fff;
}
.new-arrivals .product-price,
.trending-products .product-price {
    margin-bottom: 12px;
    color: #fcb941;
}
.for-you .product.product-2 {
    border: none;
}
.trending-products .product-body {
    padding: 16px 4px 16px 20px;
}
.ratings-container {
    margin-bottom: 15px;
}
.old-price {
    text-decoration: none;
}
.btn-product-icon {
    color: #0a98f8;
    border-color: #0a98f8;
    background-color: transparent;
    border: 1px solid #0a98f8;
}
.btn-product-icon span {
    background-color: #0a98f8;
    top: -1px;
    bottom: -1px;
}
.btn-product-icon:hover,
.btn-product-icon:focus {
    color: #0a98f8;
    border-color: #0a98f8;
    background-color: transparent;
}
.btn-wishlist:hover:before,
.btn-wishlist:focus:before {
    content: "\f233";
}
.btn-more {
    text-transform: capitalize;
}
.deal-col {
    display: flex;
    align-items: stretch;
}
.deal {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    padding: 36px 40px 48px;
    background-color: #f5f6f9;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    min-height: 420px;
}
.deal > div {
    width: 100%;
}
.deal .btn {
    padding: 0 0 2px;
}
.deal h4 {
    color: #777;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.25;
    letter-spacing: 0;
    margin-bottom: 5px;
    max-width: 240px;
}
.deal h2 {
    color: #ef837b;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    margin-bottom: 2px;
    max-width: 240px;
}
.deal .product-title {
    max-width: 240px;
    margin-bottom: 8px;
}
.deal .product-price {
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 5px;
}
.deal-content {
    align-self: center;
    margin-top: -20px;
}
.deal-bottom {
    align-self: flex-end;
}
.btn.btn-outline-dark-2 {
    border-color: #d7d7d7;
}
.deal-countdown {
    margin-bottom: 0;
    margin-left: -8px;
    max-width: 180px;
}
.deal-countdown.offer-countdown {
    max-width: 240px;
}
.deal-countdown .countdown-show3 .countdown-section {
    width: calc(33.33% - 16px);
}
.deal-countdown .countdown-show4 .countdown-section {
    width: calc(25% - 16px);
}
.deal-countdown .countdown-section {
    position: relative;
    font-weight: 400;
    font-size: 10px;
    line-height: 1;
    padding: 9px 0 3px;
    margin-left: 8px;
    margin-right: 8px;
    background-color: #0a98f8;
    border-radius: 3px;
    border: none;
    margin-bottom: 20px;
}
.deal-countdown .countdown-section:not(:last-child):after {
    color: #333333;
    content: ":";
    display: inline-block;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    position: absolute;
    left: 100%;
    margin-left: 6px;
    margin-top: -1px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.deal-countdown .countdown-period {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: -20px;
    display: block;
    font-weight: 400;
    color: #666666;
    text-transform: lowercase;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.deal-countdown .countdown-amount {
    display: inline-block;
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.03em;
    margin-bottom: 5px;
}
.new-price {
    font-weight: 400;
    letter-spacing: -0.01em;
}
.btn-link {
    letter-spacing: -0.01em;
}
.btn-link i:last-child {
    margin-left: 5px;
}
.cta-border {
    position: relative;
    border: none;
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 20px;
    padding-top: 49px;
    padding-bottom: 49px;
    height: 300px;
}
.cta-border .cta-img {
    position: absolute;
    left: -70px;
    top: -5px;
}
.cta-border .cta-text {
    margin-right: 0;
}
.cta-border .cta-text p {
    font-size: 20px;
    line-height: 1.3;
    letter-spacing: -0.01em;
    color: #fff;
}
.cta-border .cta-text::before {
    display: none;
}
.cta-border p {
    max-width: none;
}
.cta-border p strong {
    font-weight: 600;
}
.cta-border .btn:not(.btn-block) {
    min-width: 230px;
}
.cta-border .btn {
    margin-right: 70px;
    margin-left: 70px;
}
.cta-border .btn i:last-child {
    margin-left: 25px;
}
.cta-heading {
    margin-bottom: 22px;
}
.cta-heading .cta-title {
    margin-bottom: 2px;
}
.cta-title {
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.025em;
}
.cta-desc {
    font-size: 16px;
}
.cta .btn:not(.btn-block) {
    min-width: 178px;
}
.icon-boxes-container {
    padding-top: 25px;
    padding-bottom: 25px;
}
.icon-box-side .icon-box-title {
    font-weight: 600;
    margin-bottom: 2px;
}
.icon-box-side p {
    font-size: 16px;
    letter-spacing: -0.01em;
}
.icon-box-side .icon-box-icon {
    font-size: 40px;
}
.footer {
    background-color: #ffffff;
}
.footer .footer-middle {
    border-top: none;
    padding: 50px 0 35px;
}
.footer .widget-title {
    font-weight: 600;
}
.widget-list a {
    position: relative;
}
.widget-list a:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #0a98f8;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s ease;
}
.widget-list a:hover:before,
.widget-list a:focus:before {
    transform-origin: left center;
    transform: scale(1, 1);
}
.widget-about p:last-of-type {
    margin-bottom: 12px;
}
.widget-call {
    position: relative;
    font-size: 13px;
    padding: 21px 20px 16px 70px;
    border: 0.1rem solid #e1e1e1;
    border-radius: 0.3rem;
    line-height: 1.25;
    max-width: 270px;
}
.widget-call i {
    display: inline-block;
    color: #333;
    font-size: 30px;
    line-height: 1;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.widget-call a {
    display: block;
    font-weight: 400;
    font-size: 20px;
    color: #0a98f8;
}
.widget-call a:hover,
.widget-call a:focus {
    color: #1a8cff;
    box-shadow: none;
}
.footer-bottom .container {
    padding-top: 20px;
    padding-bottom: 20px;
}
@media screen and (min-width: 576px) {
    .intro-slider-container,
    .intro-slide {
        height: 360px;
    }
}
@media screen and (min-width: 768px) {
    .intro-title {
        font-size: 40px;
    }
    .intro-price {
        font-size: 40px;
    }
    .intro-price sup {
        font-size: 16px;
    }
}
@media screen and (min-width: 992px) {
    .intro-slider-container,
    .intro-slide {
        height: 400px;
    }
    .intro-price {
        font-size: 40px;
    }
    .intro-price sup {
        font-size: 1.8rem;
    }
    .intro-title {
        font-size: 40px;
    }
}
@media screen and (min-width: 1200px) {
    .intro-content .btn {
        min-width: 170px;
    }
    .intro-title {
        font-size: 50px;
    }
    .intro-price {
        font-size: 50px;
        margin-bottom: 17px;
    }
    .intro-price sup {
        top: -1.1em;
        font-size: 20px;
    }
    .owl-theme .owl-nav [class*="owl-"] {
        width: 40px;
        height: 40px;
        font-size: 22px;
        margin-top: -20px;
    }
    .btn-more {
        min-width: 270px;
    }
}
@media screen and (max-width: 991px) {
    .heading {
        text-align: center;
    }
    .heading .title {
        margin-bottom: 10px;
    }
}
/*# sourceMappingURL=demo-4.css.map */
